<template>
  <div class="">
    <h1>Scoreboard</h1>
  </div>
  <v-card outlined class="mb-4">
    <v-list-item>
      <v-list-item-content>
        <v-table density="compact">
          <thead>
            <tr>
              <th>
                #
              </th>
              <th>
                <b>Name</b>
              </th>
              <th v-for="col in scoreboard.gamelist">
                <b>{{ col }}</b>
              </th>
              <th>
                <b>Total</b>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, itemidx) in scoreboard.scoreboard">
              <td>{{ itemidx + 1 }}</td>
              <td>{{ item.name }}</td>
              <td v-for="(col, idx) in scoreboard.gamelist"> {{ item.game[idx] }}</td>
              <td>{{ item.total }}</td>
            </tr>
          </tbody>
        </v-table>
      </v-list-item-content>
    </v-list-item>
  </v-card>

</template>


<script>
export default {
  data: () => ({
    scoreboard: {
      gamelist: {

      }
    },
    neworder: {},
  }),
  mounted() {
    this.getScore();
    this.refreshInterval = setInterval(() => {
      this.getScore();
    }, 5000)
  },
  methods: {
    getScore() {
      this.axios.get("https://disasocial.ramgaard.net/api/game/get_score.php").then((response) => {
        this.scoreboard = response.data;



      })
    },
  },
  beforeUnmount() {
    clearInterval(this.refreshInterval);
  },
}

</script>