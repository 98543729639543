<template>
  <v-card>
    <v-layout>
      <v-app-bar color="primary" prominent>
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>DISA Social Event <span v-if="subtitle.length > 0">| {{ subtitle }} </span><span
            class="mdi mdi-party-popper"></span></v-toolbar-title>

        <v-spacer></v-spacer>

        <template v-if="$vuetify.display.mdAndUp">
          <!--
          <v-btn icon="mdi-magnify" variant="text"></v-btn>

          <v-btn icon="mdi-filter" variant="text"></v-btn>

          <v-btn icon="mdi-dots-vertical" variant="text"></v-btn>
          -->
        </template>


      </v-app-bar>

      <v-navigation-drawer v-model="drawer" temporary>

        <v-list :lines="false" density="compact" nav>
          <v-list-subheader>Games</v-list-subheader>
          <v-list-item v-for="(item, i) in Game_items" :key="i" :value="item" color="primary" :to="'/game/' + item.id">
            <template v-slot:prepend>
              <v-icon>mdi-ticket</v-icon>
            </template>

            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
          <v-list-subheader>System</v-list-subheader>
          <v-list-item v-for="(item, i) in System_items" :key="i" :value="item" color="primary" :to="item.link">
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>

            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item>
        </v-list>

      </v-navigation-drawer>

      <v-main>
        <v-container fluid>
          <router-view />
        </v-container>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    subtitle: "",
    drawer: false,
    group: null,
    Game_items: [
      { text: 'Scoreboard', icon: 'mdi-star' },
      { text: 'Shared with me', icon: 'mdi-ticket' },
      { text: 'Starred', icon: 'mdi-ticket' },
      { text: 'Recent', icon: 'mdi-ticket' },
      { text: 'Offline pok', icon: 'mdi-ticket' },
      { text: 'Uploads', icon: 'mdi-ticket' },
      { text: 'Backups', icon: 'mdi-ticket' },
    ],
    System_items: [
      { text: 'Users', link: '', icon: 'mdi-account-multiple' },
      { text: 'Games', link: '', icon: 'mdi-ticket' },
      { text: 'Scoreboard', link: '/', icon: 'mdi-star' },
    ],
  }),
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.axios.get("https://disasocial.ramgaard.net/api/game/get_games.php").then((response) => {
        this.Game_items = response.data;
      })
    }
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
}
</script>

<style>
.v-list-item__prepend {
  width: 30px !important;
}

th {
  font-weight: bold !important;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}

.v-data-table-header {
  background-color: rgba(182, 183, 187);
  color: white;
}

.v-data-footer {
  background-color: rgb(250, 250, 250);
}

.theme--light.v-data-table thead tr th {
  color: white;
}
</style>
